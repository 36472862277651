<template>
  <div v-if='finger'>
    <v-card flat width='200' height='350' class='ma-1'>
      <v-card-text>
        <v-img v-if='!finger.anomaly_id && (finger.data || finger.segmented_data)' contain width='100%' height='250' :src='`data:image/charset=UTF-8;png;base64,${finger.data || finger.segmented_data}`' />
        <span v-else>
          {{finger.anomaly_name}}
        </span>
      </v-card-text>

      <v-card-subtitle>
        <v-row no-gutters align='center' justify='center'>
          <v-chip>
            {{$t(fingerNames[finger.finger_index])}}
          </v-chip>
        </v-row>
      </v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'EntryDetailsFinger',
    props: {
      finger: {
        type: Object,
        default: () => undefined,
      },
    },
    data: function () {
      return {
        fingerNames: [
          'RIGHT_THUMB',
          'RIGHT_INDEX',
          'RIGHT_MIDDLE',
          'RIGHT_RING',
          'RIGHT_PINKY',
          'LEFT_THUMB',
          'LEFT_INDEX',
          'LEFT_MIDDLE',
          'LEFT_RING',
          'LEFT_PINKY',
        ],
      };
    },
  };
</script>
